import React, { useContext } from 'react';

import { withCart } from '../Cart/context';
import { AuthUserContext } from '../Session';

import { HeadingPlaceholder } from './placeholders';

import { MainColour, TextColour, TitleColour } from '../../utils/variables';

import loadComponent from '../Loadable';

const H5 = loadComponent('h5');

export default withCart(function CheckoutSummary({ items }) {
  const authUser = useContext(AuthUserContext);
  const itemName = val => {
    return val.charAt(0).toUpperCase() + val.slice(1);
  };

  return (
    <>
      <H5
        style={{ textTransform: `uppercase` }}
        fallback={<HeadingPlaceholder />}
      >
        Order <span>Summary</span>
      </H5>
      <div style={summaryItems}>
        {items.map((item, i) => {
          const cost = item.price * item.quantity;
          return (
            <div
              key={item.id}
              style={{
                marginTop: i !== 0 && `0.9375rem`,
                paddingTop: i !== 0 && `0.9375rem`,
                borderTop: i !== 0 && `1px dotted rgba(0,0,0,0.2)`,
              }}
            >
              <div style={infoStyle}>
                <strong style={{ fontSize: `1.125rem` }}>
                  {itemName(item.product)}{' '}
                  <span style={{ color: TextColour }}>
                    ({item.width}x{item.height}mm)
                  </span>
                </strong>
                <span style={{ color: MainColour, marginLeft: `auto` }}>
                  x{item.quantity}
                </span>
                <div style={detailStyle}>
                  {item.reference && (
                    <span
                      style={{
                        fontWeight: `400`,
                        fontSize: `14px`,
                        color: TextColour,
                        display: `block`,
                      }}
                    >
                      (Ref: {item.reference})
                    </span>
                  )}
                  <p style={{ margin: 0, display: `block` }}>
                    &pound;{parseFloat(Math.round(cost * 100) / 100).toFixed(2)}
                    {authUser !== null && ` (Excluding VAT)`}
                  </p>
                  {item.stock_quantity < 1 && item.backorders && (
                    <small style={smallStyle}>(Pre-Order)</small>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
});

const summaryItems = {
  display: `flex`,
  flexDirection: `column`,
};

const infoStyle = {
  display: `flex`,
  flexWrap: `wrap`,
};

const detailStyle = {
  display: `flex`,
  flexDirection: `column`,
  flex: `0 0 100%`,
};

const smallStyle = {
  color: TitleColour,
};
